export function useMaintenance() {
  const { run } = useHttp(vesselApi.public.getHealth)

  function getHealth() {
    run().then((res) => {
      if (res.data?.data && res.data.data?.monitors?.[0]?.status !== 2) {
        location.href = `${location.origin}/static/maintenance.html`
      }
    })
  }

  getHealth()

  const { pause } = useIntervalFn(getHealth, 30000)
}
